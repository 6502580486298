import { useEffect } from 'react'
import Legal from './Legal'

import './portals.css'

const Portals = () => {

  useEffect(() => {

    // close modal window with escape key
    document.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') onClickClose()
    })

  })

  const onClickClose = () => {
    const portals = document.getElementById('portals')
    if (portals !== null) {
      portals.classList.add('fade-out')
      setTimeout(() => {
        portals.scrollTop = 0
        portals.style.display = 'none'
        portals.classList.remove('fade-in')
        portals.classList.remove('fade-out')
        const portalSections = document.querySelectorAll('#portals section')
        if ( portalSections !== null ) {
          portalSections.forEach((section) => {
            section.style.display = 'none'
          })
        }
      }, 500)
    }
  }

  return (
    <div id="portals" className="portrait-only">
      <div className="inner">
        <div className="portal__header">
          {/* <a href="#" className="close" onClick={onClickClose}>Close</a> */}
          &nbsp;
        </div>
        <div className="portal__content">
          <Legal onClickClose={onClickClose} />
        </div>
      </div>
    </div>
  )

}

export default Portals