
const authUtils = {

  /*
  ---------------------------------------------------------------
  HASHSING
  ---------------------------------------------------------------
  */

  // https://stackoverflow.com/posts/65239086/revisions
  getHash(str) {
    let hash = 0
    for (let i = 0; i < str.length; ++i)
      hash = Math.imul(31, hash) + str.charCodeAt(i)
    return hash | 0
  },

  /*
  // https://stackoverflow.com/posts/43383990/revisions
  getHashPromise(str, algo = "SHA-256") {
    let strBuf = new TextEncoder().encode(str)
    return crypto.subtle.digest(algo, strBuf)
      .then(hash => {
        window.hash = hash
        let result = ''
        const view = new DataView(hash)
        for (let i = 0; i < hash.byteLength; i += 4) {
          result += ('00000000' + view.getUint32(i).toString(16)).slice(-8)
        }
        return result
      })
  }
  */

}

export default authUtils;
