import { useEffect } from 'react'
import browserUtils from "../utils/browserUtils"
import './Landscape.css'

const Landscape = () => {

  // window resize handler
  const debouncedHandleResize = browserUtils.debounce(function handleResize() {
    const domLandscapeInner = document.querySelector('div.landscape div.inner')
    if (domLandscapeInner === null) return
    domLandscapeInner.style.height = `${window.innerHeight}px`
  }, 10);

  // register window resize handler
  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    window.dispatchEvent(new Event('resize'));
  })

  return (
    <div className="landscape">
      <div className="inner">
        <p className="height-pass">Please rotate your mobile device to <span className="nowrap">view the website.</span></p>
        <p className="height-fail">Please view the website on <span className="nowrap">a larger device.</span></p>
      </div>
    </div>
  )

}

export default Landscape