
const cookieUtils = {

  /*
  ---------------------------------------------------------------
  ACCESSORS
  ---------------------------------------------------------------
  */

  setLastClip(key) {
    cookieUtils.set('last_clip', key, 365)
  },

  getLastClip() {
    const val = cookieUtils.get('last_clip')
    if (typeof val !== 'string') return ''
    return val
  },

  /*
  ---------------------------------------------------------------
  COOKIE HELPERS
  ---------------------------------------------------------------
  */

  get(cookieName) {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  set(cookieName, cookieValue, expiryInDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expiryInDays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
  }

}

export default cookieUtils;
