
const dataUtils = {

  /*
  ---------------------------------------------------------------
  CONFIG
  ---------------------------------------------------------------
  */

  getCustomRevealDelay(item, language = 'en') {
    const revealKey = `${language}_reveal`
    if (!item.hasOwnProperty('captions')) return false
    if (!item.captions.hasOwnProperty(revealKey)) return false
    const reveal = item.captions[revealKey]
    if (!Array.isArray(reveal) || reveal.length === 0) return false
    return reveal
  },

  getMediaDuration(item) {
    if (!item.hasOwnProperty('media')) return 0
    if (!item.media.hasOwnProperty('duration')) return 0
    const mediaDuration = item.media['duration']
    if (typeof mediaDuration === 'undefined' || isNaN(mediaDuration) || parseInt(mediaDuration) === 0) return 0
    return parseInt(mediaDuration) * 1000
  },

  getMediaVolume(item, def) {
    if (!item.hasOwnProperty('controls')) return def
    if (!item.controls.hasOwnProperty('volume')) return def
    const volume = item.controls.volume
    if (typeof volume === 'undefined' || isNaN(volume) || parseInt(volume) === 0) return def
    return parseInt(volume) / 100
  },

}

export default dataUtils;
