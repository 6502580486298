
const browserUtils = {

  /*
  ---------------------------------------------------------------
  PROPERTIES
  ---------------------------------------------------------------
  */

  pages: {
    count: 0
  },

  /*
  ---------------------------------------------------------------
  HACKS
  ---------------------------------------------------------------
  */

    // hack: enable audio-on-swipe on iOS devices by playing short (quiet) sound clip on touch
  playQuietSoundHack() {
    const quietSound = new Audio();
    quietSound.autoplay = true;
    quietSound.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
  },

  /*
  ---------------------------------------------------------------
  BROWSER LOCATION and PAGE TITLE
  ---------------------------------------------------------------
  */

  // for browser history
  getPageById(id = 0) {
    id = parseInt(id)
    if (id === 0) return false
    const pageKey = `page_${id}`
    if (!this.pages.hasOwnProperty(pageKey)) return false
    return this.pages[pageKey]
  },

  // for browser history (uses count as simple id)
  newPage(page) {
    this.pages.count++
    const id = this.pages.count
    const pageKey = `page_${id}`
    this.pages[pageKey] = page
    return id
  },

  updateUrlAndTitle(url = '', title = '') {
    if (!window.history || !window.history.pushState) return
    const pageUrl = (url !== '') ? url : '/'
    const pageTitle = (title !== '') ? title : document.title
    const page = {
      url: pageUrl,
      title: pageTitle
    }
    const pageId = this.newPage(page)
    window.history.pushState({ 'id': pageId }, pageTitle, pageUrl);
  },

  /*
  ---------------------------------------------------------------
  DARK MODE
  ---------------------------------------------------------------
  */

  isDarkMode() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) return true
    return false
  },

  /*
  ---------------------------------------------------------------
  DEBOUNCING
  ---------------------------------------------------------------
  */

  // debounce handler to throttle React callbacks
  debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

}

export default browserUtils;
