import dataUtils from './dataUtils'

const clipUtils = {

  /*
  ---------------------------------------------------------------
  PROPERTIES
  ---------------------------------------------------------------
  */

  clipTracker: [],

  /*
  ---------------------------------------------------------------
  CLIP PLAY
  ---------------------------------------------------------------
  */

  playCaptions(item, audioKey) {

    // require captions
    const captions = document.querySelector(`#${audioKey} p.captions`)
    if (captions === null) {
      console.log('Exit:', 'p.captions not found')
      return
    }
  
    // require clip duration > 0 ms
    const itemDuration = dataUtils.getMediaDuration(item)  // milliseconds
    let countdown = itemDuration/1000
    if (itemDuration === 0) {
      console.log('Exit:', 'itemDuration = 0')
      return
    }

    // get clipKey
    const clipKey = this.getLastKey()
    // console.log('clipKey', clipKey)

    // setup
    const customRevealDelay = dataUtils.getCustomRevealDelay(item)
    const wordCount = captions.innerHTML.split('</span>').length - 1
    const delay = Math.floor((itemDuration - 650) / (wordCount))  // default reveal speed
    let delayArray

    // create array of reveal word delays
    if (!customRevealDelay) {
      // fixed delay between each word
      delayArray = wordCount === 0 ? 1 : Array.apply(null, Array(wordCount)).map(function (x, i) { return delay; })
      delayArray[0] = 50
    } else {
      // configurable delay between each word
      delayArray = Array.from({ length: wordCount }, (val, i) => {
        if (i >= customRevealDelay.length) return customRevealDelay[customRevealDelay.length - 1]
        return customRevealDelay[i]
      })
    }

    // register duration countdown
    // console.log('countdown', countdown)
    let countdownDelay = 0
    while (countdown > -1) {
      setTimeout(function (seconds) {
        const ele = document.querySelector(`.${clipKey} .duration`)
        if (ele === null) return
        ele.innerHTML = new Date(seconds * 1000).toISOString().substr(15, 4)
      }, countdownDelay, countdown)
      countdownDelay = countdownDelay + 1000
      countdown--
    }
    for (let i=countdown; i >= 0; i--) {
      countdown--
    }

    // register delayed word reveal
    setTimeout(() => {
      let cumulativeDelayArray = []
      let cumulativeDelay = 0
      for (let i = 0; i < wordCount; i++) {
        setTimeout(function () {
          const nextWord = document.querySelector(`.${clipKey} p.captions span.ipm-light`)
          if (nextWord === null) return
          nextWord.classList.remove('ipm-light')
        }, cumulativeDelay + delayArray[i])
        cumulativeDelay = cumulativeDelay + delayArray[i]
        cumulativeDelayArray.push(cumulativeDelay)
      }
    }, 1)

  },

  /*
  ---------------------------------------------------------------
  CLIP LIFECYCLE EVENTS
  ---------------------------------------------------------------
  */

  changeSlide(slide = null) {
    if (slide === null) {
      console.log('NULL')
      return
    }
    this.removeClipKeyFromSlide()
    this.addClipKeyToSlide(slide)
  },

  addClipKeyToSlide(slide = null) {
    if (slide === null) return
    this.removeClipKeyFromSlide()     // stop previous clip, if applicable
    const newKey = this.getNewKey()
    slide.classList.add(newKey)
    console.log(newKey, slide)
    return newKey
  },

  removeClipKeyFromSlide() {
    const lastKey = this.getLastKey()
    // console.log('removeClipKeyFromSlide()', 'lastKey', lastKey)
    if (lastKey === null) return false
    const ele = document.querySelector(`div.${lastKey}`)
    if (ele === null) return
    ele.classList.remove(lastKey)
    return true
  },

  /*
  ---------------------------------------------------------------
  KEYS
  ---------------------------------------------------------------
  */

  getNewKey() {
    const newKey = 'clip_play_' + (Object.keys(this.clipTracker).length + 1)
    this.clipTracker.push(newKey)
    return newKey
  },

  getLastKey() {
    const lastKey = this.clipTracker[(this.clipTracker.length - 1)]
    if (typeof lastKey === 'undefined') return null
    return lastKey
  },

  getLastKeyWithCreateIfNew() {
    const lastKey = this.getLastKey()
    if (lastKey !== null) return lastKey
    return this.getNewKey()
  }

}

export default clipUtils;
