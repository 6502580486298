import ButtonLink from '../components/ButtonLink'
import './Legal.css'

const Legal = ({ onClickClose }) => {

  const closePortal = () => { onClickClose() }

  return (
    <section id="portal__legal" className="portal__legal">

      <div tabIndex="0"></div>
      <div role="dialog" aria-labelledby="legal_dialog_label" aria-modal="true">

        <h1 id="legal_dialog_label">Legal</h1>

        <p>
          Human Rights Foundation (HRF) is a nonpartisan non-profit organization that promotes and protects human rights globally. Donations to the Ukraine Solidarity Fund go toward providing stable shelf food, sterile medical equipment, essential medicines needed for survival, baby formula, diapers and creams, feminine care items, and other life-saving goods that are out of stock in regions under invasion and bombardment. Note that some participants of the Voices From Inside project are associated with Publicis Groupe.
        </p>

        <ButtonLink id="legal_dialog_close" classes="close btnLink" tabIndex="0" text="Return to website" onClick={closePortal} />
        
      </div>

    </section>
  )

}

export default Legal
