import { Link } from 'react-router-dom'
import ButtonLink from './ButtonLink'
import './Header.css';

const Header = () => {

  const onClickLegal = (e) => {
    e.target.blur()
    const portals = document.getElementById('portals')
    const legalPortal = document.getElementById('portal__legal')
    if (portals !== null && legalPortal !== null) {
      legalPortal.style.display = 'block'
      portals.style.display = 'block'
      portals.classList.add('fade-in')
    }
  }

  return (
    <>
      <nav id="ally-nav" className="sr-only">
        <a tabIndex="0" href="/#main-content">Skip to main content</a>
        <a tabIndex="0" href="/#footer-donate-heading">Skip to donation information</a>
      </nav>
      <header className='header'>
        <div className="inner">
          <ButtonLink classes="legal btnLink" tabIndex="0" text="LEGAL" onClick={onClickLegal} />
          <Link className="logo" to='/'>
            <img className="dark-mode-display-none" src="/images/logo-hrf.svg" alt="Voices from Inside is presented by the Human Rights Foundation. Click here to return to main screen." />
            <img className="dark-mode-display-block" src="/images/logo-hrf-dark-mode.svg" alt="Voices from Inside is presented by the Human Rights Foundation. Click here to return to main screen." />
          </Link>
        </div>
      </header>
    </>
  )

}

export default Header
