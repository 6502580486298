
const slideUtils = {

  /*
  ---------------------------------------------------------------
  PROPERTIES
  ---------------------------------------------------------------
  */

  state: {
    changeCount: 0,
    active: {
      key: '',
      type: '',
      ele: {}
    },
    initial: {
      key: '',
      type: '',
      isDeeplinked: false
    },
    intro: {
      key: ''
    },
    last: {
      key: '',
      type: ''
    }
  },

  errors: [],

  /*
  ---------------------------------------------------------------
  CHANGE
  ---------------------------------------------------------------
  */

  slideChange() {
    this.state.changeCount++
    return this.state.changeCount
  },

  /*
  ---------------------------------------------------------------
  INITIAL
  ---------------------------------------------------------------
  */

  getInitialSlideKey() {
    return this.state.initial.key
  },

  isInitialSlide() {
    if (this.state.initial.key === '') return true // not defined, therefore true (assuming no other errors)
    const container = this.requireQuerySelector(document, '.swiper-slide-active .media__swipe__item', 'isInitialSlide()')
    if (container !== false) {
      const containerId = container.id
      if (typeof containerId === 'string') {
        console.log('isInitialSlide', containerId, this.state.initial.key)
        if (containerId === this.state.initial.key) return true
      }
    }
    return false
  },

  setInitialSlide(isDeeplinked = false) {
    if (this.state.initial.key !== '') {
      console.log('is already set')
      return
    }
    this.state.initial.key = this.state.active.key
    this.state.initial.type = this.state.active.type
    if (typeof isDeeplinked === 'boolean') this.state.initial.isDeeplinked = isDeeplinked
    console.log('set:', this.state.initial)
  },

  /*
  ---------------------------------------------------------------
  ACTIVE SLIDE
  ---------------------------------------------------------------
  */

  getActiveSlideKey() {
    return this.state.active.key
  },

  getActiveSlideType() {
    return this.state.active.type
  },

  getActiveSlideEle() {
    return this.state.active.ele
  },

  setActiveSlide() {
    const activeSlide = this.requireQuerySelector(document, '.swiper-slide-active', 'setActiveSlide()')
    if (!activeSlide) return false
    const container = this.requireQuerySelector(activeSlide, '.media__swipe__item', 'setActiveSlide()')
    if (!container) return false
    const containerId = container.id
    if (typeof containerId !== 'string' || containerId === '') {
      //this.errors.push(['setActiveSlide()', '[containerType]', 'missing attribute: id'])
      return false
    }
    const containerType = container.getAttribute('data-type')
    if (containerType === null) {
      this.errors.push(['setActiveSlide()', '[containerType]', 'missing attribute: data-type'])
      return false;
    }
    this.state.active.ele = activeSlide
    this.state.active.key = containerId
    this.state.active.type = containerType
    return true
  },

  /*
  ---------------------------------------------------------------
  DEEPLINKS
  ---------------------------------------------------------------
  */

  addDeeplink() {
    //console.log('slideUtils.addDeeplink()')
    let ele = document.querySelector('.swiper-slide-active')
    if (ele === null) return false
    setTimeout(() => {
      console.log(ele)
      ele.classList.add('swiper-slide-deeplink')
      return true
    }, 50)
  },

  removeDeeplink(delay = 100) {
    //console.log('slideUtils.removeDeeplink()')
    if (parseInt(delay) === 0) delay = 100
    let ele = document.querySelector('.swiper-slide-deeplink')
    if (ele === null) return false
    setTimeout(() => {
      ele.classList.remove('swiper-slide-deeplink')
      return true
    }, delay)
  },

  /*
  ---------------------------------------------------------------
  LAST SLIDE
  ---------------------------------------------------------------
  */

  getLastSlideKey() {
    return this.state.last.key
  },

  setLastSlideKey(key = '') {
    if (typeof key !== 'string') {
      console.group('slideUtils.setLastSlideKey()')
      console.log('Error:', 'provided key was not a string')
      console.groupEnd()
      return
    }
    if (key === '') {
      console.group('slideUtils.setLastSlideKey()')
      console.log('Error:', 'empty key')
      console.groupEnd()
      return
    }
    this.state.last.key = key
  },

  /*
  ---------------------------------------------------------------
  VALIDATION: DOM
  ---------------------------------------------------------------
  */

  requireQuerySelector(sourceEle, selector, label) {
    const ele = sourceEle.querySelector(selector)
    if (ele === null) {
      this.errors.push([label, `no match for selector: ${selector}`])
      return false
    }
    return ele
  },

  /*
  ---------------------------------------------------------------
  VALIDATION: VALUES
  ---------------------------------------------------------------
  */

  isValidKey(key, label = '') {
    if (typeof key !== 'string') {
      this.errors.push([label, '[key]', 'string required'])
      return false
    }
    if (key === '') {
      this.errors.push([label, '[key]', 'non-empty string required'])
      return false
    }
    return true
  }

}

export default slideUtils;
