import { useState } from 'react'
import PropTypes from "prop-types"
// import parse from 'html-react-parser'
import './MediaSwipeItemAudio.css'

const useCaptionEffect = true

const MediaSwipeItemAudio = ({ item, tapedeckPlay, tapedeckStop, removeDeeplink, onToggleAudio, mutedButtonClass, unmutedButtonClass }) => {

  const [captions, setCaptions] = useState('');
  //const [encodedCaptions, setEncodedCaptions] = useState('');

  const duration = new Date(item.media.duration * 1000).toISOString().substr(15, 4)
  const offsetClass = item.controls.waveform.hasOwnProperty('offsetClass') ? item.controls.waveform.offsetClass : ''

  const photoSrc = `/media/${item.media.folder}/${item.media.photo}`
  const photoAlt = `${item.contributor} (${item.location})`
  // const flagSrc = `/images/icon-flag-ua.svg`
  // const flagAlt = ''

  if (captions === '') {
    if (useCaptionEffect) {
    setCaptions('<span class="ipm-light">' + item.captions.en.split(' ').join('</span> <span class="ipm-light">') + '</span>')
    //setEncodedCaptions(captions)
    } else {
      setCaptions(item.captions.en)
      //setEncodedCaptions(item.captions.en)
    }
  }

  // get audio key and return to parent component
  const toggleAudio = (e) => {
    removeDeeplink()
    const parent = e.target.closest('.media__swipe__item')
    const audioKey = parent.getAttribute('id')
    tapedeckPlay(audioKey, item.key)
  }

  return (
    <div data-contributor={item.media.folder} data-key={item.key} data-type="audio" className="media__swipe__item media__swipe__item__audio">
      <div className="inner">
        <div className="group">

          <button className="toggle-audio" onClick={onToggleAudio}>
            <img className={mutedButtonClass + ' dark-mode-display-none'} src="/images/icon-muted.svg" alt="icon indicating sound is muted" />
            <img className={mutedButtonClass + ' dark-mode-display-block'} src="/images/icon-muted-darkmode.svg" alt="icon indicating sound is muted" />
            <img className={unmutedButtonClass + ' dark-mode-display-none'} src="/images/icon-unmuted.svg" alt="icon indicating sound is muted" />
            <img className={unmutedButtonClass + ' dark-mode-display-block'} src="/images/icon-unmuted-darkmode.svg" alt="icon indicating sound is muted" />
          </button>

          <img className="icon-mic-ua dark-mode-display-none" src="/images/icon-mic-ua.png" alt="Microphone icon" />
          <img className="icon-mic-ua dark-mode-display-block" src="/images/icon-mic-ua-darkmode.png" alt="Microphone icon" />

          <div className="contributor">
            <p className="name ipm-medium">{item.contributor}</p>
            <p className="location ipm-light">{item.location}</p>
            {/* <img className="icon-flag" src={flagSrc} alt={flagAlt} /> */}
          </div>

          <div className="duration ipm-light show-tablet show-desktop" data-duration={parseFloat(item.media.duration)}>{duration}</div>

          <div className="contributor-photo">
            <img className="icon-play" id={`${item.key}_click`} src="/images/icon-play.png" alt="Click to hear audio clip" onClick={toggleAudio} />
            <img className="contributor" id={`${item.key}_swipe`} src={photoSrc} alt={photoAlt} onClick={toggleAudio} />
          </div>

          <div className={`waveform ${offsetClass} ${item.key}`} aria-hidden="true" />

          <p className="captions ipm-regular" dangerouslySetInnerHTML={{ __html: captions }} />
          <textarea className="captions" defaultValue={captions} />

        </div>
        </div>
      <img className="voice-bubble-bottom dark-mode-display-none" src="/images/voice-bubble-bottom.png" alt="Decorative voice bubble element" />
      <img className="voice-bubble-bottom dark-mode-display-block" src="/images/voice-bubble-bottom-dark-mode.png" alt="Decorative voice bubble element" />
    </div>
  )

};

MediaSwipeItemAudio.defaultProps = {
  captions: {
    en: '',
    en_reveal: []
  },
  contributor: '',
  controls: {
    volume: 0,
    waveform: {
      barHeight: 1
    }
  },
  key: '',
  location: '',
  media: {
    duration: 0,
    folder: '',
    format: 'audio',
    language: '',
    photo: '',
    source: '',
  },
  timestamp: ''
}

MediaSwipeItemAudio.propTypes = {
  captions: PropTypes.shape({
    en: PropTypes.string.isRequired,
    en_reveal: PropTypes.arrayOf(PropTypes.number).isRequired
  }),
  contributor: PropTypes.string.isRequired,
  controls: PropTypes.shape({
    volume: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    waveform: PropTypes.shape({
      barHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    })
  }),
  location: PropTypes.string.isRequired,
  media: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    folder: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
  }),
  timestamp: PropTypes.string.isRequired
}

export default MediaSwipeItemAudio;
