import { useEffect } from 'react'
import browserUtils from "../utils/browserUtils"

import Header from '../components/Header'
import MediaSwipe from '../components/MediaSwipe/MediaSwipe'
import Footer from '../components/Footer'

// controls
const heightMultiplier = 1                // 1.1 slides => .909

// DOM elements
let domHeader, domClipWrapper, domFooter  // single elements
let domClipItem, domClipInner             // multiple elements

const MainSwiper = ({ config }) => {

  /*
  const isPortraitOrientation = () => {
    if (Math.abs(window.orientation) === 90) return false
    return true
  }
  */

  // window resize
  const debouncedHandleResize = browserUtils.debounce(function handleResize() {

    const windowHeight = window.innerHeight
    const headerHeight = domHeader.clientHeight
    const footerHeight = domFooter.clientHeight
    const mediaHeight = (windowHeight - (headerHeight + footerHeight))
    const mediaHeightAdjusted = mediaHeight * heightMultiplier

    domClipWrapper.style.height = `${mediaHeight}px`

    domClipItem.forEach(ele => {
      ele.style.height = `${mediaHeightAdjusted}px`
    })

    domClipInner.forEach(ele => {
      ele.style.height = `${mediaHeightAdjusted - 34}px`
    })

  }, 10);

  useEffect(() => {

    // get DOM elements
    domHeader = document.querySelector('header')
    domClipWrapper = document.querySelector('div.media-wrapper > div.media__swipe')
    domClipItem = document.querySelectorAll('div.media__swipe__item')
    domClipInner = document.querySelectorAll('div.media__swipe__item .inner')
    domFooter = document.querySelector('footer')

    // listen for window resize event and trigger on load
    window.addEventListener('resize', debouncedHandleResize);
    window.dispatchEvent(new Event('resize'));

    // listen for change of orientation
    window.addEventListener('orientationchange', function (event) {
      debouncedHandleResize()
    });

    // listen for becoming visible (eg, user clicks back to browser tab with website)
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === 'visible') window.dispatchEvent(new Event('resize'));
    });

  }, [debouncedHandleResize]);

  return (
    <div className="main portrait-only">
      <Header />
      <main id="main-content">
        <MediaSwipe config={config} />
      </main>
      <Footer />
    </div>
  )

}

export default MainSwiper
