/* eslint-disable eqeqeq */

import cookieUtils from './cookieUtils'
import authUtils from './authUtils'

import media from '../data/media.json'
import settings from '../data/settings.json'

const configUtils = {

  /*
  PROPERTIES
  */

  config: {},

  invalidConfig: {
    isValid: false,
    useMaintenanceMode: true,
    errors: []
  },

  /*
  INIT
  */

  getEnvConfig() {
    // require valid config
    if (!this.isValidSettings()) return this.invalidConfig
    // get base config for runtime environment
    const env = this.getEnv()
    switch (env) {
      case 'production':
      case 'development':
      case 'stage':
        this.config = {
          "isValid": true, 
          "env": env,
          ...settings.environments[env]
        }
        break;
      default:
        // invalid environment gets invalid config
        return this.invalidConfig
    }
    // dynamic setup
    this.disableConsole()       // prevents output of debugging information
    this.config.maintenanceMode.enabled = this.forceMaintenanceMode() ? true : false // block requests and show maintenance page?
    this.buildPlaylist()
    // remove data from config and return
    delete this.config.logging
    delete this.config.maintenanceMode.bypass
    return this.config
  },

  /*
  PLAYLIST
  */

  buildPlaylist() {
    // reorder playlist if return visit
    this.reorderPlaylist()
    // deeplink
    this.config.isDeeplinked = this.setupDeeplink() ? true : false
    // build playlist
    const loadAllMedia = (typeof this.config.playlist.loadAllMedia === 'boolean') ? this.config.playlist.loadAllMedia : false
    let keys = loadAllMedia ? Object.keys(media) : this.config.playlist.keys
    let items = {}
    keys.forEach(key => {
      if (!media.hasOwnProperty(key)) return
      items[key] = media[key]
      items[key]['key'] = key
    })
    this.config.media = items
  },

  /*
  CLIP ORDER
  */

  randomizePlaylistKeyArray(unshuffled) {
    if (!Array.isArray(unshuffled)) return []
    return unshuffled
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
  },

  // reorder if clip memory is used, starting from last viewed clip + 1
  reorderPlaylist() {
    if (this.config.playlist.randomize) {
      this.config.playlist.keys = this.randomizePlaylistKeyArray(this.config.playlist.keys)
      return
    }
    if (this.config.playlist.rememberLastClip) {
      const lastClipKey = cookieUtils.getLastClip()
      if (lastClipKey !== '' && this.config.playlist.keys.includes(lastClipKey)) {
        const position = this.config.playlist.keys.indexOf(lastClipKey)
        console.log('reorder', 'lastClipKey', lastClipKey, position, this.config.playlist.keys)
        const arr = this.config.playlist.keys.slice(position + 1).concat(this.config.playlist.keys.slice(0, position + 1))
        this.config.playlist.keys = arr
      }
    }
  },

  /*
  DEEPLINKING
  */

  setupDeeplink() {
    const querystring = new URLSearchParams(window.location.search)
    const deeplinkKey = querystring.get("ref")
    if (typeof deeplinkKey !== 'string' || deeplinkKey === '') return false
    if (!media.hasOwnProperty(deeplinkKey)) return false
    if (!this.config.playlist.keys.includes(deeplinkKey)) return false
    // remove key from playlist
    this.config.playlist.keys = this.config.playlist.keys.filter(function (value, index, arr) {return value !== deeplinkKey})
    // insert key at beginning of playlist
    this.config.playlist.keys.unshift(deeplinkKey)
    return true
  },

  /*
  MAINTENANCE MODE
  */

  // hash method #1: simple numeric hash
  forceMaintenanceMode() {
    if (!this.config.hasOwnProperty('maintenanceMode')) return false
    if (this.config.maintenanceMode.enabled !== true) return false
    if (!this.config.maintenanceMode.hasOwnProperty('bypassHash')) return false
    const querystring = new URLSearchParams(window.location.search)
    const inputVal = querystring.get('bypass')
    if (inputVal !== null && inputVal.length > 0) {
      const hash = authUtils.getHash(inputVal)
      if (typeof hash === 'number' || typeof hash === 'string') {     // only accept numbers and strings
        if (hash == this.config.maintenanceMode.bypassHash) return false  // use loose equality (allow "1234" == 1234)
      }
    }
    return true
  },

  // hash method #2: SHA-256 using native JavaScript crypto library (produces longer hash)
  // IMPORTANT: work in progress -- refactoring needed to enable support for returned Promise
  /*
  forceMaintenanceModePromise() {
    if (!this.config.hasOwnProperty('maintenanceMode')) return false
    if (this.config.maintenanceMode.enabled !== true) return false
    if (!this.config.maintenanceMode.hasOwnProperty('bypassHash')) return false
    const querystring = new URLSearchParams(window.location.search)
    const inputVal = querystring.get('bypass')
    console.log(typeof inputVal)
    if (inputVal !== null && inputVal.length > 0) {
      // method #2: returns promise
      const result = authUtils.getHashPromise(inputVal)
                            .then( data => {
                              console.log('verify', this.config.maintenanceMode.bypassHash, data)
                              if (this.config.maintenanceMode.bypassHash == data) return false
                              return true
                            })
      return result
    }
    return true
  },
  */

  /*
  ENVIRONMENT
  */

  getEnv() {
    // default: evaluate domain against mappings defined in settings.json (allows: development, stage, production)
    if (settings.hasOwnProperty('mappings')) {
      const host = window.location.host
      if (settings.mappings.hasOwnProperty(host)) {
        const mapping = settings.mappings[host]
        switch (mapping) {
          case 'development':
          case 'stage':
          case 'production':
            return mapping
          default:
            // unsupported environment
        }
      }
    }
    // fallback: Node environment (allows: development, production)
    switch (process.env.NODE_ENV) {
      case 'development':
        return 'development'
      default:
        return 'production' // final outcome
    }
  },

  /*
  VALIDATION
  */

  isValidSettings() {
    /* REQUIRE: ENVIRONMENTS */
    if (!settings.hasOwnProperty("environments")) {
      this.invalidConfig.errors.push('Config: missing "environments" collection')
      return false
    }
    /* VALIDATE: ENVIRONMENTS */
    let isValid = true
    // TODO
    return isValid
  },

  /*
  DEBUGGING
  */

  disableConsole() {
    if (!this.config.disableConsole) return
    console.error = function no_console() { }
    console.group = function no_console() { }
    console.groupEnd = function no_console() { }
    console.info = function no_console() { }
    console.log = function no_console() { }
    console.warn = function no_console() { }
  },

}

export default configUtils;
