import configUtils from './configUtils'
import WaveSurfer from "../lib/wavesurfer.js"

const waveformUtils = {

  loadWavesurferAudio(wavesurfer, media, contributorSlides, swiperConfig) {

    // config: delay
    let delay = 0
    const delayIncrement = 100

    // base URL of source audio
    let audioFileBaseUrl = ''
    const env = configUtils.getEnv()
    console.log('loadWavesurferAudio', 'env', env)
    switch (env) {
      case 'development':
        // no change
        break;
      default:
        // full URL, including protocol and domain name
        audioFileBaseUrl = window.location.protocol + '//' + window.location.host
    }

    // iterate slides, adding unique ID to each one
    const slides = document.querySelectorAll('.swiper-wrapper .swiper-slide .media__swipe__item')
    slides.forEach(slide => {

      // early exit: error matching slide to media
      const itemKey = slide.getAttribute('data-key')
      if (!itemKey) return
      if (!media.hasOwnProperty(itemKey)) {
        console.log(`Couldn't find media with key ${itemKey}`)
        return;
      }

      delay = delay + delayIncrement
      setTimeout(function (slide){

        // add unique DOM ID to each slide
        const contributor = slide.getAttribute('data-contributor')
        if (!contributorSlides.hasOwnProperty(contributor)) {
          contributorSlides[contributor] = 0
        }
        const contributorSlideNumber = contributorSlides[contributor] + 1
        const contributorSlideId = `${contributor}_${contributorSlideNumber}`
        contributorSlides[contributor] = contributorSlideNumber
        slide.setAttribute('id', contributorSlideId);

        // get media item, setup properties
        const item = media[itemKey]

        // build file URL
        const cacheBuster = new Date().getTime()
        const audioFile = `${audioFileBaseUrl}/media/${item.media.folder}/${item.media.source}?${cacheBuster}`
        console.log('audioFile', audioFile)

        // waveform properties
        const barHeight = (item.controls.waveform.hasOwnProperty('barHeight')) ? item.controls.waveform.barHeight : 2

        // create waveform
        wavesurfer[contributorSlideId] = WaveSurfer.create({
          container: `#${contributorSlideId} .waveform`,
          width: '290px',
          waveColor: swiperConfig.waveColor,
          progressColor: swiperConfig.progressColor,
          cursorWidth: 0,
          barGap: 2,
          barHeight: barHeight,
          barWidth: 3,
          barRadius: 0,
        });
        wavesurfer[contributorSlideId].load(audioFile);

        // disable waveform click by appending floating invisible overlay
        wavesurfer[contributorSlideId].on('ready', function () {
          const waveform = slide.querySelector('div.waveform')
          if (waveform !== null) {
            waveform.insertAdjacentHTML('beforeend', '<div class="waveform-noclick" />')
          }
        })

      // all done
      //console.log('contributor', contributor, 'contributorSlideId', contributorSlideId)

      }, delay, slide)

    })

  },

}

export default waveformUtils;
