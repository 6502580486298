import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import configUtils from './utils/configUtils'

import Landscape from './pages/Landscape'
import MainSwipe from './pages/MainSwipe'
import Maintenance from './pages/Maintenance'
import Portals from './portals/Portals'

// get config customized for runtime environment (maintenance mode, use intro slide, playlist)
const config = configUtils.getEnvConfig()
console.log('config', config)

function App() {

  // portraitOnly only applies to smartphones (landscape on tablets is supported)
  const wrapperClass = config.layout.portraitOnly ? 'block-landscape' : 'allow-rotate'
  console.log('wrapperClass', wrapperClass)

  return (
    <div className={`wrapper ${wrapperClass}`}>
      <div className="container portrait-only">
        <Router>
          <Routes>
            {
              config.maintenanceMode.enabled && 
                <Route path='*' element={<Maintenance />} />
            }{
              !config.maintenanceMode.enabled && 
              <Route path='*' element={<MainSwipe config={config} />} />
            }
          </Routes>
        </Router>
      </div >
      {
        config.layout.portraitOnly && <Landscape />
      }
      <Portals />
    </div >
  );

}

export default App;
