import { Link } from 'react-router-dom'
import Button from './Button'
import './Footer.css'

const Footer = () => {

  const donationUrl = 'https://hrf.kindful.com/?campaign=1184760'

  const onClickDonate = () => {
    window.open(donationUrl, '_self')
    // window.open(donationUrl, '_blank')
  }

  return (
    <footer className='footer' add-role='contentinfo' aria-labelledby='footer-donate-heading'>
      <div className='inner'>
        <div id="footer-donate-heading" className="cta">
          <p tabIndex="0" className="text-small">
            Delivering Life Saving <span className="nowrap">Aid to Ukraine</span>
          </p>
          <div className="sr-only">
            <p>Use your up and down arrow keys for audio clips and accompanying text captions from from citizens in Ukraine.</p>
            <Link to="#" onClick={onClickDonate}>Click here to donate today to the Human Rights Foundation's Ukraine Solidarity Fund. Link redirects to donation form on Kindful.com.</Link>
          </div>
        </div>
        <div className="actions">
          <Button 
            id='footer-button-donate'
            classes='btn ipm-bold' 
            text='DONATE TODAY' 
            aria-label="Visit the Human Rights Foundation on Kindful.com to make a donation" 
            onClick={onClickDonate} 
          />
        </div>
      </div>
    </footer>
  )

}

export default Footer
