import { useEffect, useState } from 'react'
import MediaSwipeItemAudio from './MediaSwipeItemAudio'
import MediaItemIntro from './MediaSwipeItemIntro'
// import parse from 'html-react-parser'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { A11y, Keyboard, Mousewheel, Manipulation, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/mousewheel';
import 'swiper/css/navigation';

import browserUtils from '../../utils/browserUtils'
import clipUtils from "../../utils/clipUtils"
import cookieUtils from "../../utils/cookieUtils"
import dataUtils from "../../utils/dataUtils"
import slideUtils from "../../utils/slideUtils"
import waveformUtils from "../../utils/waveformUtils"

import './MediaSwipe.css'
import './MediaSwipeItem.css'

SwiperCore.use([A11y, Keyboard, Mousewheel, Manipulation, Navigation])

// UX controls
let wavesurfer = {};
const useVerticalSwiper = true

// local state properties
let audioIsReady = false
let slideConfig = {}
// let deeplinkDisabled = false

// audio play hack
// let playedAudioHack = false

const getSlideConfig = (isDarkMode) => {
  const waveColor = isDarkMode ? '#b1b1b1' : '#5a5a5a'
  const progressColor = isDarkMode ? 'black' : 'white'
  if (useVerticalSwiper) {
    return {
      'className': 'media__swipe__vertical',
      'waveColor': waveColor,
      'progressColor': progressColor
    }
  }
  return {
    'className': 'media__swipe__vertical',
    'slidesPerView': 1,
    'heightMultiplier': 1,
    'waveColor': waveColor,
    'progressColor': progressColor
  }
}

const MediaSwiper = ({ config }) => {

  const [swiperRef, setSwiperRef] = useState(null);
  const [playQuietSoundHack, setPlayQuietSoundHack] = useState(true)
  const [lastAudioKey, setLastAudioKey] = useState(null)
  const [isDarkMode, setIsDarkMode] = useState(browserUtils.isDarkMode());
  const [mutedButtonClass, setMutedButtonClass] = useState('active')
  const [unmutedButtonClass, setUnmutedButtonClass] = useState('disabled')

  // update deeplinkDisabled
  // if (config.isDeeplinked) deeplinkDisabled = !config.isDeeplinked

  // slide state and controls
  slideConfig = getSlideConfig(isDarkMode)
  const contributorSlides = {}
  // let slideNumber = 0

  // audio toggle
  const onToggleAudio = () => {
    if (mutedButtonClass === 'active') {
      console.log('audio on')
      setMutedButtonClass('disabled')
      setUnmutedButtonClass('active')
      if (lastAudioKey !== null && wavesurfer[lastAudioKey].isPlaying()) wavesurfer[lastAudioKey].setMute(false)
    } else {
      console.log('audio off')
      setMutedButtonClass('active')
      setUnmutedButtonClass('disabled')
      if (lastAudioKey !== null && wavesurfer[lastAudioKey].isPlaying()) wavesurfer[lastAudioKey].setMute(true)
    }
  }

  useEffect(() => {

    if (!audioIsReady) {
      console.log('do: initAudio()')
      initAudio()
    }

    // hack: enable audio-on-swipe on iOS devices by playing short (quiet) sound clip on touch
    window.addEventListener('touchstart', () => {
      if (!playQuietSoundHack) return
      setPlayQuietSoundHack(false)
      browserUtils.playQuietSoundHack()
    })

    // watch for dark mode toggle
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      setIsDarkMode(browserUtils.isDarkMode())
      slideConfig = getSlideConfig(isDarkMode)
    });

  }, [audioIsReady, playQuietSoundHack, slideConfig]);

  return (
    <div className='media-wrapper'>
      <div className={`media__swipe ${slideConfig.className}`}>
        {
          typeof config.media !== 'undefined' && Object.entries(config.media).length > 0 &&
          <Swiper
              modules={[A11y, Keyboard, /*Mousewheel,*/ Navigation]}
              className="media__carousel"
              keyboard={{ enabled: true }}
              mousewheel={false}
              /*mousewheel={true}*/
              // navigation
              longSwipes={true}
              loop={false}
              breakpoints={{
                0: {
                  direction: 'vertical',
                  slidesPerView: 1,
                  spaceBetween: 14
                },
                768: {
                  direction: 'horizontal',
                  slidesPerView: 1,
                  spaceBetween: 14
                },
              }}
              onSlideChange={(swiper) => {
                console.group('Swiper event: change')
                const changeCount = slideUtils.slideChange()
                console.log('changeCount', changeCount)
                // check if user has just left initial slide
                if (changeCount === 1) {
                  // YES: remove deeplink (if present) or initial slide (if specified in settings)
                  console.log('config.isDeeplinked', config.isDeeplinked)
                  if (config.isDeeplinked) {
                    // remove deeplink play overlay
                    console.group('slideUtils.removeDeeplink()')
                    slideUtils.removeDeeplink()
                    config.isDeeplinked = false
                    console.groupEnd()
                  } else if (config.slides.removeIntro) {
                    // remove intro slide
                    console.log('Remove initial slide')
                    setTimeout(() => {
                      try {
                        swiperRef.removeSlide(0)
                        swiperRef.updateSlides()
                      } catch (e) {
                        // edge case caused by unrealistic user actions
                        // worst-case scenario: intro slide remains
                        console.log(e)
                      }
                      //slideUtils.setActiveSlide()
                    }, config.slides.removeIntroDelay)
                    console.groupEnd()
                  }
                }
                // context: always execute unless initial slide was just removed
                setTimeout(() => {
                  if (!audioIsReady) {
                    console.log('onSlideChange', 'exit', '!audioIsReady')
                    console.groupEnd()
                    return
                  }
                  const callbackDelay = changeCount === 1 && config.slides.removeIntro ? (config.slides.removeIntroDelay+1) : 1
                  setTimeout(() => {
                    onSlideChange()
                  }, callbackDelay)
                }, 10)
                console.log('process change event')
                console.groupEnd()
              }}
              onSwiper={(swiper) => {
                setSwiperRef(swiper)
                setTimeout(() => {
                  console.group('Swiper event: init')
                  console.group('slideUtils.setActiveSlide()')
                  const activeSlideKey = slideUtils.getActiveSlideKey()
                  if (activeSlideKey === '')
                    slideUtils.setActiveSlide()
                  else
                    console.log('is already set')
                  console.groupEnd()
                  console.group('slideUtils.setInitialSlide()')
                  slideUtils.setInitialSlide(config.isDeeplinked)
                  console.groupEnd()
                  console.groupEnd()
                }, 1)
              }}
          >
            {!config.isDeeplinked && config.slides.showIntro &&
              <SwiperSlide key='intro'>
                <MediaItemIntro />
              </SwiperSlide>
            }
            {Object.entries(config.media).map((item) => (
              <SwiperSlide key={item[1].key}> {/* key={slideNumber++} */}
                <MediaSwipeItemAudio
                  /*key={item[1].key}*/
                  item={item[1]}
                  tapedeckPlay={tapedeckPlay}
                  tapedeckStop={tapedeckStop}
                  removeDeeplink={removeDeeplink}
                  onToggleAudio={onToggleAudio}
                  mutedButtonClass={mutedButtonClass}
                  unmutedButtonClass={unmutedButtonClass}
                />
              </SwiperSlide>
            ))}
            {config.isDeeplinked && config.slides.showIntro &&
              <SwiperSlide key='intro'>
                <MediaItemIntro />
              </SwiperSlide>
            }
          </Swiper>
        }
      </div>
    </div>
  )

  /*
  ---------------------------------------------------------------
  INIT
  ---------------------------------------------------------------
  */

  function initAudio() {
    if (audioIsReady) return                            // already initialized
    if (config.isDeeplinked) slideUtils.addDeeplink()   // add deeplink CSS
    waveformUtils.loadWavesurferAudio(wavesurfer, config.media, contributorSlides, slideConfig)  // create waveforms
    audioIsReady = true                                 // update ready state
    console.log(wavesurfer)
  }

  /*
  ---------------------------------------------------------------
  PLAYBACK CONTROL
  ---------------------------------------------------------------
  */

  function rememberLastClip(slideId) {
    const lastSlide = document.getElementById(slideId)
    if (lastSlide !== null) {
      const lastClipKey = lastSlide.getAttribute('data-key')
      console.log(lastClipKey, lastSlide)
      if (typeof lastClipKey === 'string') cookieUtils.setLastClip(lastClipKey)
    }
  }

  function resetLastSlide(lastSlideKey) {
    // require valid input
    if (lastSlideKey === null) {
      console.log('Error:', 'key of previous slide is null')
      return
    }
    if (lastSlideKey === '') {
      console.log('Error:', 'key of previous slide is empty')
      return
    }
    if (lastSlideKey === 'intro') {
      console.log('Skip:', 'previous slide was intro')
      return
    }
    if (!wavesurfer.hasOwnProperty(lastSlideKey)) {
      console.log('Skip:', `no wavesurfer instance for provided key ${lastSlideKey}`)
      return
    }
    // update last clip key
    //rememberLastClip(lastSlideKey)
    // reset waveform
    console.log('Target:', lastSlideKey)
    console.log('Stop playback')
    wavesurfer[lastSlideKey].pause()
    wavesurfer[lastSlideKey].seekTo(0)
    // get captions
    const lastSlide = document.getElementById(lastSlideKey)
    if (lastSlide === null) {
      console.log('Error:', 'unable to get slide')
      return
    }
    let captionSource = lastSlide.querySelector('textarea.captions')
    if (captionSource === null) {
      console.log('Error:', 'unable to get slide textarea.captions')
      return
    }
    let captionTarget = lastSlide.querySelector('p.captions')
    if (captionTarget === null) {
      console.log('Error:', 'unable to get slide p.captions')
      return
    }
    // reset captions after brief delay
    console.log('Reset captions')
    setTimeout(function () {
      captionSource = captionSource.value
      captionTarget.innerHTML = captionSource
    }, 100)
  }

  /*
  ---------------------------------------------------------------
  EVENT: SLIDE CHANGE
  ---------------------------------------------------------------
  */

  function onSlideChange() {

    console.group( 'onSlideChange()' )

    // get active slide
    slideUtils.setActiveSlide()
    const activeKey = slideUtils.getActiveSlideKey()
    const activeType = slideUtils.getActiveSlideType()
    const activeEle = slideUtils.getActiveSlideEle()
    const lastKey = slideUtils.getLastSlideKey()
    console.log('activeKey', activeKey, activeType)
    console.log('lastKey', lastKey)

    // reset last slide (if applicable)
    if (activeKey !== lastKey && lastKey !== '') {
      console.log('lastKey', lastKey)
      console.group('resetLastSlide()')
      resetLastSlide(lastKey)
      console.groupEnd()
    }

    // only continue if active slide contains audio
    if (activeType !== 'audio') {
      console.groupEnd()
      return
    }

    // register slide clip
    console.group('clipUtils.changeSlide')
    clipUtils.changeSlide(activeEle)
    console.groupEnd()

    // play slide
    const photo = activeEle.querySelector('img.contributor')
    if (photo === null) {
      console.log('photo is null')
      return
    }
    setTimeout(() => {
      console.log('photo click')
      photo.click()
    }, 200)

    // set last slide (to currently active slide)
    slideUtils.setLastSlideKey(activeKey)

    console.groupEnd()

  }

  /*
  ---------------------------------------------------------------
  CALLBACKS: PLAY / STOP
  ---------------------------------------------------------------
  */

  function tapedeckPlay(audioKey, dataKey) {

    /*
    ----------------------------
    PART 1: VALIDATION & SETUP
    ----------------------------
    */

    // exit immediately if instance is already playing (pause is not supported)
    if (wavesurfer[audioKey].isPlaying()) return

    let isValidRequest = true
    const activeKey = slideUtils.getActiveSlideKey()
    const initialKey = slideUtils.getInitialSlideKey()

    console.group('tapedeckPlay()')
    console.log('audioKey', audioKey)
    console.log('activeKey', activeKey)
    console.log('initialKey', initialKey)
    console.log('dataKey', dataKey)

    if (audioKey === initialKey) {

      // add clip key to initial slide, since onSlideChange was never triggered
      const activeEle = slideUtils.getActiveSlideEle()
      clipUtils.addClipKeyToSlide(activeEle)
      slideUtils.setLastSlideKey(activeKey)

    } else {

      // this function is called after a delay, so exit if activeSlideId doesn't match the audioKey
      if (audioKey !== activeKey) {
        console.log('Exit:', 'audioKey !== activeKey')
        isValidRequest = false
      }

    }

    // require registered wavesurfer reference
    if (!wavesurfer.hasOwnProperty(audioKey)) {
      console.log('Exit:', `unregistered wavesurfer instance (${audioKey})`)
      isValidRequest = false
    }

    // exit on error
    if (!isValidRequest) {
      console.groupEnd()
      return
    }

    /*
    ----------------------------
    PART 2: PLAYBACK
    ----------------------------
    */

    // update last audio key and clip key
    setLastAudioKey(audioKey)
    rememberLastClip(audioKey)

    // setup clip and wavesurfer
    const item = config.media[dataKey]

    // clip duration
    // const wavesurferDuration = wavesurfer[audioKey].getDuration()
    // console.log('wavesurferDuration', wavesurferDuration)
    // const duration = dataUtils.getMediaDuration(item)

    // volume -- DO NOT USE
    // a bug on iOS can result in playback if volume is set, even if setMute is true
    // const itemVolume = dataUtils.getMediaVolume(item)
    //wavesurfer[audioKey].setVolume(itemVolume)

    // mute
    if (unmutedButtonClass === 'active') {
      wavesurfer[audioKey].setMute(false)
    } else {
      wavesurfer[audioKey].setMute(true) 
    }

    wavesurfer[audioKey].setMute(true)
    if (unmutedButtonClass === 'active') {
      wavesurfer[audioKey].setMute(false)
    }

    // playback
    console.group('clipUtils.playCaptions()')
    clipUtils.playCaptions(item, audioKey)
    console.groupEnd()

    wavesurfer[audioKey].play()

    console.groupEnd()

  }

  function tapedeckStop(audioKey, dataKey) {
    wavesurfer[audioKey].play();
  }

  /*
  ---------------------------------------------------------------
  CALLBACK: DEEPLINKS
  ---------------------------------------------------------------
  */

  // called by: MediaSwipe, MediaSwipeItemAudio
  function removeDeeplink() {
    if (!config.isDeeplinked) return
    slideUtils.removeDeeplink()
  }

}

export default MediaSwiper
