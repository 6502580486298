import PropTypes from 'prop-types'
import './Button.css'

const Button = ({classes, onClick, text}) => {

  classes = classes.replace(',', ' ')

  return <button 
    className={classes}
    onClick={onClick}
    >
      {text}
    </button>;
};

// default button click (if no function is provided in props)
const onClickVoid = () => {}

Button.defaultProps = {
  classes: "btn",
  text: "",
  onClick: onClickVoid,
};

Button.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button
