// import Header from '../components/Header'
// import Footer from '../components/Footer'
import './Maintenance.css'

const Maintenance = () => {
  return (
    <div className="main portrait-only">
      <main id="maintenance">
        <div className="inner">
          <h1>Sorry!</h1>
          <p className="pb15">
            We've taken the website offline for updates. In the meantime we invite <span className="nowrap">you to visit:</span>
          </p>
          <p className="pb15">
            <a className="block bold pb10" target="_blank" rel="noreferrer" href="https://hrf.org/">Human Rights Foundation</a>
            <small>
              With our community of activists, policy experts, philanthropists, we create innovative solutions to the world’s most pressing problem, and inspire millions <span className="nowrap">of supporters.</span>
            </small>
          </p>
          <p>
            <a className="block bold pb10" target="_blank" rel="noreferrer" href="https://www.operationsunflower.com/">Operation Sunflower</a>
            <small>
              Operation Sunflower is a coalition of Ukrainian and American relief partners delivering aid at the request of Ministry of Reintegration of the Temporarily Occupied Territories <span className="nowrap">of Ukraine</span>
            </small>
          </p>
        </div>
      </main>
    </div>
  )
}

export default Maintenance
