import './MediaSwipeItemIntro.css'

const MediaItemIntro = () => {

  const cacheBuster = '?' + new Date().getTime()

  const photoSrc = `/images/animation-intro.gif?${cacheBuster}`
  const photoSrcDarkmode = `/images/animation-intro-darkmode.gif?${cacheBuster}`

  return (
    <div className="media__swipe__item media__swipe__item__intro" data-type="intro" id="intro">
      <div className="inner">
        <div className="group">
          <h1 className="ipm-light">
            Voices<br />
            From<br />
            Inside
          </h1>
          <div className="media__swipe__item__intro__welcome">
            <img className="animation dark-mode-display-none" src={photoSrc} alt="Introductory slide. Use up and down arrow keys to navigate. Audio will automatically play on each slide in supported web browsers. Text captions are provided for each audio clip." />
            <img className="animation dark-mode-display-block" src={photoSrcDarkmode} alt="Introductory slide. Use up and down arrow keys to navigate. Audio will automatically play on each slide in supported web browsers. Text captions are provided for each audio clip." />
            <p className="mobile-only">Swipe <b>Up</b> to listen</p>
            <p className="desktop-only">Swipe <b>Left</b> to listen</p>
          </div>
        </div>
      </div>
      <img className="voice-bubble-bottom dark-mode-display-none" src="/images/voice-bubble-bottom.png" alt="Decorative voice bubble element" />
      <img className="voice-bubble-bottom dark-mode-display-block" src="/images/voice-bubble-bottom-dark-mode.png" alt="Decorative voice bubble element" />
    </div>
  )

};

export default MediaItemIntro;
